<template>
  <CHeader with-subheader class="">
    <CHeaderNav class="d-lg-none bg-main">
      <b-button
        variant="link"
        class="d-lg-none text-sub"
        @click="$store.commit('toggleSidebarCustom')"
      >
        <font-awesome-icon icon="bars" size="lg" />
      </b-button>
      <div class="d-none d-md-block d-lg-block d-xl-block">
        <CBreadcrumbRouter class="my-1 border-0" />
      </div>
    </CHeaderNav>

    <b-nav class="d-none d-lg-flex nav-customs pl-2">
      <b-nav-item target="_blank" to="/"
        ><div
          class="logo-home-page-desktop"
          v-bind:style="{
            'background-image': 'url(' + $store.state.logoHomePage + ')',
          }"
        ></div>
      </b-nav-item>
      <b-nav-item  to="/home">หน้าแรก</b-nav-item>
      <b-nav-item-dropdown
        text="ลูกค้า"
        :toggle-class="[
          'nav-link-custom',
          { active: activeUrl == '/customer' },
        ]"
        left
      >
        <b-dropdown-item to="/customer/purchase-history"
          >กลุ่มลูกค้าที่มียอดซื้อ</b-dropdown-item
        >
        <b-dropdown-item to="/customer/prospect"
          >กลุ่มลูกค้าที่ไม่มียอดซื้อ</b-dropdown-item
        >
        <b-dropdown-item to="/customer/privileged"
          >กลุ่มลูกค้าที่ได้รับสิทธิพิเศษ</b-dropdown-item
        >
      </b-nav-item-dropdown>
      <!-- <b-nav-item v-if="isSpa == 'true'" target="_blank" to="/spa">สปา</b-nav-item> -->
      <b-nav-item @click="register" v-if="$route.meta.activeUrl === '/home'">ลงทะเบียน</b-nav-item>
      <b-nav-item
        @click="changeTime"
        v-if="allowTimeChange == 'true' && isFullPos == 0"
        >ปรับเวลา</b-nav-item
      >
      <b-nav-item target="_blank" to="/summary">สรุปยอด</b-nav-item>
      <b-nav-item target="_blank" to="/transaction">บิล</b-nav-item>
      <b-nav-item target="_blank" to="/transaction-error"
        >บิลที่มีปัญหา ({{
          totalTransactionError | numeral("0,0")
        }})</b-nav-item
      >
      
      <b-nav-item-dropdown
        text="รางวัล"
        :toggle-class="['nav-link-custom', { active: activeUrl == '/redeem' }]"
        left
      >
        <b-dropdown-item to="/redeem">แลกคะแนนสะสม</b-dropdown-item>
        <b-dropdown-item to="/mission">Membership Activity</b-dropdown-item>
      </b-nav-item-dropdown>
      <b-nav-item-dropdown
        text="รายงาน"
        :toggle-class="['nav-link-custom', { active: activeUrl == '/report' }]"
        left
      >
        <b-dropdown-item to="/report/point-balance">เช็คคะแนน</b-dropdown-item>
        <b-dropdown-item to="/report/ref-sale-member"
          >ประวัติการซื้อ</b-dropdown-item
        >
        <b-dropdown-item v-if="isSpa == 'true'" to="/report/spa-usage-history"
          >ประวัติการใช้</b-dropdown-item
        >
        <b-dropdown-item v-if="isSpa == 'true'" to="/report/outstanding"
          >Outstanding</b-dropdown-item
        >
        <b-dropdown-item to="/report/history-sale-category"
          >ประวัติการซื้อแบ่งตามประเภทสินค้า</b-dropdown-item
        >
      </b-nav-item-dropdown>
      <b-nav-item v-if="showInventory" target="_blank" to="/inventory">จัดการสินค้า</b-nav-item>
    </b-nav>
    <b-nav
      class="d-none d-lg-flex nav-customs justify-content-center align-items-center text-right ml-auto pr-2"
    >
      <div class="mr-2">
        <p>Store : {{ accountName }}</p>
        <p>
          {{
            $moment(timestamp).format("DD/MM/YYYY HH:mm:ss")
          }}
          {{ plantId }}
        </p>
      </div>
      <div class="icon-power-off" @click="$refs.modalLogout.handleLogout()">
        <font-awesome-icon icon="power-off" title="logout" />
      </div>
    </b-nav>
    <ModalLogout ref="modalLogout" />
    <ModalRegister ref="modalRegister" :branchId="branchId" />
    <ModalChangeTime ref="modalChangeTime" :branchId="branchId" />
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from "./TheHeaderDropdownAccnt";
import ModalLogout from "@/components/modal/alert/ModalLogout.vue";
import ModalRegister from "@/components/customer/ModalRegister.vue";
import ModalChangeTime from "@/components/modal/ModalChangeTime.vue";
import { mapGetters } from "vuex";

export default {
  name: "TheHeader",
  components: {
    TheHeaderDropdownAccnt,
    ModalLogout,
    ModalRegister,
    ModalChangeTime,
  },
  data() {
    return {
      displayName: "",
      name: "",
      modalMessage: "Are you sure you want to log out ?",
      timestamp: "",
      totalTransactionError: 0,
      activeUrl: "/",
      myInterval: null
    };
  },
  created: async function () {
    if (this.$route.meta.isChildren)
      this.activeUrl = this.$route.meta.activeUrl;
    this.myInterval = setInterval(this.getNow, 1000);
    await this.getTotalTransactionError();
    // setTimeout(() => {
    //   this.handleActiveMenuChild(this.$route.meta);
    // }, 0);
  },
  computed: {
    ...mapGetters({
      accountName: "getAccountName",
      branchId: "getBranchId",
      plantId: "getPlantId",
      isSpa: "getSpa",
      allowTimeChange: "getAllowTimeChange",
      isFullPos: "getIsFullPos",
      showInventory: "getShowInventory",
      dateTimeFix: "getDateTimeFix",
    }),
  },
  watch: {
    "$route.meta"(newValue, oldValue) {
      if (newValue.isChildren) this.activeUrl = newValue.activeUrl;
      // this.handleActiveMenu(newValue, oldValue);
    },
    "dateTimeFix"(newValue, oldValue) {
      if(newValue != oldValue) {
        clearInterval(this.myInterval)
        this.timestamp = this.$moment(newValue).add(543, "years");
      }
    },
  },
  methods: {
    getTotalTransactionError() {
      this.axios
        .get(`${this.$baseUrl}/transactionerror/total/${this.branchId}`)
        .then((data) => {
          this.totalTransactionError = data.count;
        });
    },
    getNow: function () {
      this.timestamp = this.$moment().add(543, "years");
    },
    // clickChangeBrand(brand) {
    //   window.location.href = `/batch?brandID=${brand.id}`;
    // },
    handleActiveMenu: async function (newValue, oldValue) {
      if (newValue.activeUrl != oldValue.activeUrl) {
        await this.handleRemoveActiveMenuChild(oldValue);
      }
      await this.handleActiveMenuChild(newValue);
    },
    handleActiveMenuChild: async function (meta) {
      // .ps__rail-y
      var ele = await this.getAllElementsWithAttribute(
        "a",
        "href",
        `${meta.activeUrl}`
      );
      var parentElement = ele.parentElement.parentElement.parentElement;

      if (parentElement && parentElement.className == "c-sidebar-nav-dropdown")
        parentElement.setAttribute("class", "c-sidebar-nav-dropdown c-show");

      if (meta.lv == 1 && ele.className == "c-sidebar-nav-dropdown")
        ele.setAttribute("class", "c-sidebar-nav-dropdown c-show");
      else if (
        meta.lv == 3 &&
        parentElement.parentElement.parentElement.className ==
          "c-sidebar-nav-dropdown"
      )
        parentElement.parentElement.parentElement.setAttribute(
          "class",
          "c-sidebar-nav-dropdown c-show"
        );

      if (meta.isChildren && ele.className == "c-sidebar-nav-link")
        await ele.setAttribute(
          "class",
          "router-link-exact-active c-active c-sidebar-nav-link"
        );

      setTimeout(() => {
        var div = document.getElementsByClassName("ps__rail-y")[0];
        div.style.top = `${ele.getBoundingClientRect().top}px`;
        ele.focus();
      }, 500);
      this.routeMeta = meta;
    },
    handleRemoveActiveMenuChild: async function (oldMeta) {
      var oldEle = this.getAllElementsWithAttribute(
        "a",
        "href",
        oldMeta.activeUrl
      );
      var parentElement = oldEle.parentElement.parentElement.parentElement;

      if (
        parentElement &&
        parentElement.className == "c-sidebar-nav-dropdown c-show"
      )
        parentElement.setAttribute("class", "c-sidebar-nav-dropdown");

      if (
        oldMeta.lv == 1 &&
        oldEle.className == "c-sidebar-nav-dropdown c-show"
      )
        oldEle.setAttribute("class", "c-sidebar-nav-dropdown");
      else if (
        oldMeta.lv == 3 &&
        parentElement.parentElement.parentElement.className ==
          "c-sidebar-nav-dropdown c-show"
      )
        parentElement.parentElement.parentElement.setAttribute(
          "class",
          "c-sidebar-nav-dropdown"
        );

      if (
        oldMeta.isChildren &&
        oldEle.className ==
          "router-link-exact-active c-active c-sidebar-nav-link"
      )
        await oldEle.setAttribute("class", "c-sidebar-nav-link");
    },
    getAllElementsWithAttribute(tagName, attribute, val) {
      var allElements = Array.from(document.getElementsByTagName(tagName));
      var filtered = allElements.filter((ele) => {
        return (
          ele.getAttribute(attribute) !== null &&
          ele.getAttribute(attribute) == val
        );
      });

      if (filtered.length) return filtered[0];
      else return false;
    },
    register() {
      this.$refs.modalRegister.show();
    },
    changeTime() {
      this.$refs.modalChangeTime.show();
    },
  },
};
</script>
<style lang="scss" scoped>
.logo-home-page-desktop {
  height: 46px !important;
  width: 46px !important;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

::v-deep .dropdown-menu.show {
  padding: 0 !important;
}

::v-deep .nav-customs {
  font-weight: bold;
  height: 46px;
  li.nav-item:first-child {
    .nav-link {
      padding: 0px !important;
      border: 0 !important;
      margin-right: 5px;
    }
  }
  li.nav-item {
    .nav-link,
    .nav-link.nav-link-custom {
      padding: 10px 10px !important;
      color: #000000;
      border-top: 3px solid white;
      border-bottom: 3px solid white;
      margin-right: 5px;
      font-size: 14px;
      height: 46px !important;
      span {
        color: #000000;
      }
    }
    .nav-link:hover,
    .nav-link.active {
      border-color: var(--primary-color);
    }
  }
}
.icon-power-off {
  cursor: pointer;
  text-align: center;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  border: 1px none #fff;
  background: linear-gradient(
    -90deg,
    var(--secondary-color) 50%,
    var(--primary-color) 50%
  );
  svg {
    color: white;
    font-size: 26px;
    margin: 6px;
  }
}

@media (min-width: 992px) {
  .c-header {
    .dropdown-menu {
      padding: 0 !important;
    }
    ul:last-child {
      p {
        margin-bottom: 0;
        font-size: 16px;
        line-height: 18px;
      }
    }
  }
  .c-header {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    background-color: white !important;
    border: 0 !important;
    min-height: 46px;
    color: #000000;
    font-size: 14px;
  }
}

@media (max-width: 1200px) {
  ::v-deep .nav-customs {
    font-size: 12px;
    li.nav-item {
      .nav-link,
      .nav-link.nav-link-custom {
        font-size: 12px;
      }
    }
}
}
</style>
